<template>
<div class="container">
<div class="image-grid">

<div class="image-card">
  <div class="main-image">
    <img :src="image_1.imgSrc" alt="">
  </div>
  <div class="image-name">
    {{image_1.desc}}
  </div>
</div>

<div class="image-card">
  <div class="main-image">
    <img :src="image_2.imgSrc" alt="">
  </div>
  <div class="image-name">
    {{image_2.desc}}
  </div>
</div>

<div class="image-card">
  <div class="main-image">
    <img :src="image_3.imgSrc" alt="">
  </div>
  <div class="image-name">
    {{image_3.desc}}
  </div>
</div>

</div>
</div>
</template>

<script>
export default {
props: {
    image_1: {type:Object, required:true},
    image_2: {type:Object, required:true},
    image_3: {type:Object, required:true}
}
}
</script>

<style scoped>

.image-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  color: black;
  font-weight: 700;
  padding: 200px 0;
}

.image-card {
  background-color: red;
}

.main-image {
  height: 250px;
}

.main-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-name {
  background-color: white;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 2px 9px 0px rgba(0,0,0,1);
  -moz-box-shadow: 0px 2px 9px 0px rgba(0,0,0,1);
  box-shadow: 0px 2px 9px 0px rgba(0,0,0,1);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

@media(max-width:800px) {
  .image-grid {
   
    flex-direction: column;
  }
}


</style>