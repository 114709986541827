<template lang="pug">
main.about-page
	a.whatsappWrapper(href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!")
		img(src='../assets/img/WhatsApp.svg')
	display-header(:img="require('@/assets/overscans/pool.jpg')",headline="Plunge Into Water of Happiness")
	.container
		full-image-section(style="padding-top:5rem",:img='require("@/assets/img/real_site_images/resized/swimming_pool2.jpg")',alt="swag")

		right-side-section(
			title="Life is Better Poolside"
			:img='require("@/assets/img/real_site_images/resized/swimming_pool1.jpg")'
			description="A free form deck leisure pool surrounds the area, circled with soothing palm trees and comfortable seating where you can enjoy perfect moments; resting, reading, and enjoying the sunshine."
		)
			<span style="font-weight: 600;">Note:</span> Swimming costume is compulsory for swimming pool.

		left-side-section(
			title="Jacuzzi"
			:img='require("@/assets/img/real_site_images/resized/pool_view_cropped.jpg")'
			description="People have always been naturally drawn to water as a source of comfort—the soothing sounds of a bubbling brook, the relaxing steam of a natural hot spring. Water therapy in a Jacuzzi Hot Tub combines all the best calming features of water: opening the blood vessels to improve circulation, relaxing the muscles and providing an escape from the pressures of daily life."
		)
		full-image-section(:img='require("@/assets/img/real_site_images/resized/swimming_pool3.jpg")',alt="swag")

</template>

<script>
import DisplayHeader from '../components/DisplayHeader.vue'
import RightSideSection from '../components/XRightSideSection.vue'
import LeftSideSection from '../components/XLeftSideSection.vue'
import FullImageSection from '../components/XFullImageSection.vue'

export default {
	components: {
	DisplayHeader,
    RightSideSection,
    LeftSideSection,
	FullImageSection
  },
}
</script>

<style>
</style>