<template lang="pug">
.about-us
	.container
		.block-1
			.content
				h2 {{title}}
				p {{description}}
				slot
		.block-2
			.about-image
				img.img-cover(:src="img",:alt=title)
		.clearfix

</template>

<script>
export default {
	props:{
		img:{type:String, requried:true},
		title:{type:String, requried:true},
		description:{type:String, requried:true},
	}
}
</script>

<style>

@media (max-width: 800px) {


 h2{
		font-size: 30px;
	}

}

</style>