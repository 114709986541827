<template lang="pug">
.about-us
	.container
		.block-1(style="margin: 0px; width: 43%")
			.about-image
				img.img-cover(:src="img",:alt="title")
		.block-2(style="margin-left: 100px; weight: calc(43% - 200px)")
			.content
				h2 {{title}}
				p {{description}}
				slot
		.clearfix

</template>

<script>
export default {
	props:{
		img:{type:String, requried:true},
		title:{type:String, requried:true},
		description:{type:String, requried:true},
	}
}
</script>