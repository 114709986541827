<template lang="pug">

.main-container
	.img-container
		img(:src="img",:alt="headline")
	.logo-container
		img(:src="require('@/assets/img/logo-solo.svg')",alt="Time Square Logo")
	.welcome-text {{tagline}}
	h1 {{headline}}
		br
		span.soon(v-if="headline=='Spa & Hammam'") (Hammam Opening Soon) 

</template>

<script>
import ImageRowSection from './ImageRowSection.vue'

export default {
	components: {ImageRowSection},
	props:{
		img: {
			type:String,
			required: true
		},
		headline: {
			type:String,
			required: true
		},
		tagline: {
			type:String,
			default: "WELCOME TO TIME SQUARE RESORT & SPA"
		},
	}
};
</script>

<style scoped>

.soon {

font-size: 2.3rem;
}

.main-container {
	color: black;
	background-color: #d7d5d0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 0 60px 0;
}

.img-container {
	width: 100%;
	aspect-ratio: 3;
	object-fit: cover;
	overflow: hidden;
}

.logo-container img {
	width: 3rem;
	margin: 1rem;
}

.img-container img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.welcome-text {
	margin: 1rem;
	margin-top: 0;
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
	opacity: 0.5;
	font-weight: 400;
}

h1 {
	margin: 0;
	width: 80%;
	font-family: "DM Serif Display",serif;
	font-size: 3rem;
	
	font-weight: 400;
}



.welcome-text, h1 {
	text-align: center;
}

@media(max-width: 1024px) {
	h1{
		font-size: 2rem;
	}
}
</style>