<template lang="pug">
img.about-page-img.img-cover(:src="img",:alt="alt")

</template>

<script>
export default {
	props:{
		img:{type:String, requried:true},
		alt:{type:String,defalt:""}
		}
}
</script>